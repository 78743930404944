import { Checkbox, SxProps, Theme, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { DishItem, OrderStatus, OrderType } from './types';
import { useRef } from 'react';
import useDraggableScroll from 'use-draggable-scroll';
import { useOrders } from '../../../hooks/use-orders';

const styles: Record<string, SxProps<Theme>> = {
  container: {
    margin: 1,
    padding: 1,
    borderRadius: 3,
    bgcolor: (theme: Theme) => theme.palette.background.paper,
  },
  toppingContainer: {
    display: 'flex',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      height: 5,
    },
    '&::-webkit-scrollbar-thumb': {
      bgcolor: 'grey',
      borderRadius: 1,
    },
  },
  topping: {
    display: 'flex',
    flexDirection: 'column',
    marginX: 1.5,
    marginTop: 1,
    minWidth: 'max-content',
  },
};

interface DishProps {
  dish: DishItem;
  index: number;
  order: OrderType;
}

const Dish = ({ dish, index, order }: DishProps) => {
  const ref = useRef(null);
  const { onMouseDown } = useDraggableScroll(ref);

  const { toggleIsDishChecked } = useOrders();

  return (
    <Box sx={styles.container}>
      <Box display={'flex'} alignItems={'center'}>
        {order.status === OrderStatus.IN_PROGRESS && (
          <Checkbox
            checked={dish.isChecked}
            size='small'
            onChange={() =>
              toggleIsDishChecked(order.orderId, dish.orderedDishId)
            }
          />
        )}
        <Typography fontWeight={'bold'}>{`${index}. ${dish.name}`}</Typography>
      </Box>

      <Box sx={styles.toppingContainer} ref={ref} onMouseDown={onMouseDown}>
        {dish?.selectedToppings &&
          Object.entries(dish?.selectedToppings).map(([key, val]) => (
            <Box key={key}>
              {!val.selectedItems.length ? null : (
                <Box sx={styles.topping}>
                  <Typography
                    variant='body2'
                    sx={{ textDecoration: 'underline' }}
                  >
                    {key}
                  </Typography>
                  {val.selectedItems?.map(({ name }) => (
                    <Typography key={name} variant='caption'>
                      {name}
                    </Typography>
                  ))}
                </Box>
              )}
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default Dish;
