import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
  IconButton,
  ListItemIcon,
  SvgIcon,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import RamenDiningIcon from '@mui/icons-material/RamenDining';
import { OrderMode, SortField } from './types';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { translate } from '../../../translation/translate';

const styles: Record<string, SxProps<Theme>> = {
  container: {
    '& .MuiPaper-root': {
      bgcolor: (theme) => theme.palette.background.default,
    },
  },
  sortButton: {
    position: 'absolute',
    left: 10,
  },
};

interface SortItem {
  name: SortField;
  icon: JSX.Element;
}

interface SortProps {
  setOrdersModes: Dispatch<SetStateAction<OrderMode[]>>;
  orderMode: OrderMode;
}

const getIcon = (
  icon: typeof SvgIcon,
  orderMode: OrderMode,
  sortBy: SortField
): JSX.Element => (
  <SvgIcon
    component={icon}
    fontSize='small'
    sx={{
      color: (theme) =>
        orderMode.sortBy === sortBy ? theme.palette.primary.main : null,
    }}
  />
);

const Sort = ({ setOrdersModes, orderMode }: SortProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const sortMenuItems: SortItem[] = useMemo(
    () => [
      {
        name: SortField.ORDER_TIME,
        icon: getIcon(AccessTimeIcon, orderMode, SortField.ORDER_TIME),
      },
      {
        name: SortField.DELIVER_TIME,
        icon: getIcon(EmojiPeopleIcon, orderMode, SortField.DELIVER_TIME),
      },
      {
        name: SortField.DISHES_COUNT,
        icon: getIcon(RamenDiningIcon, orderMode, SortField.DISHES_COUNT),
      },
    ],
    [orderMode]
  );

  const onMenuClick = (field: SortField) => {
    setOrdersModes((prev) =>
      prev.map((mode) =>
        mode.name === orderMode.name ? { ...mode, sortBy: field } : mode
      )
    );
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton sx={styles.sortButton} onClick={handleClick}>
        <LowPriorityIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={styles.container}
      >
        <Typography textAlign={'center'} fontWeight={'bold'}>
          {translate('Sort by')}
        </Typography>

        {sortMenuItems.map(({ name, icon }) => (
          <MenuItem onClick={() => onMenuClick(name)} key={name}>
            <ListItemIcon>{icon}</ListItemIcon>
            <Typography
              fontWeight={orderMode.sortBy === name ? 'bold' : 'normal'}
              color={(theme) =>
                orderMode.sortBy === name
                  ? theme.palette.primary.main
                  : theme.palette.text.primary
              }
            >
              {translate(name)}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default Sort;
