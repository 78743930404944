import {
  FC,
  ReactNode,
  createContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { OrderStatus, OrderType } from '../Components/Views/OrdersView/types';
import { ORDERS_MOCK } from '../mocks/orders-mock';

interface OrdersContextType {
  orders: OrderType[];
  changeOrderStatus: (orderId: string, newStatus: OrderStatus) => void;
  toggleIsDishChecked: (orderId: string, dishId: string) => void;
  isExpanded: (orderId: string) => boolean;
  toggleIsExpanded: (orderId: string) => void;
  expandOrder: (orderId: string) => void;
  shrinkOrder: (orderId: string) => void;
}

const initialContext: OrdersContextType = {
  orders: [],
  changeOrderStatus: () => {},
  toggleIsDishChecked: () => {},
  toggleIsExpanded: () => {},
  isExpanded: () => false,
  expandOrder: () => {},
  shrinkOrder: () => {},
};

export const OrdersContext = createContext<OrdersContextType>(initialContext);

const OrderProvider = ({ children }: { children: ReactNode }) => {
  const [orders, setOrders] = useState<OrderType[]>(ORDERS_MOCK);
  const [expandedOrders, setExpandedOrders] = useState<string[]>([]);

  const firstTimeOrdersExist = useRef<boolean>(true);

  const changeOrderStatus = (orderId: string, newStatus: OrderStatus) => {
    if (newStatus === OrderStatus.DELIVERED) {
      shrinkOrder(orderId);
    }
    setOrders((prev) =>
      prev.map((order) =>
        order.orderId === orderId ? { ...order, status: newStatus } : order
      )
    );
  };

  const isExpanded = (orderId: string): boolean =>
    expandedOrders.some((arg) => arg === orderId);

  const expandOrder = (orderId: string) => {
    if (!isExpanded(orderId)) {
      setExpandedOrders((prev) => [...prev, orderId]);
    }
  };

  const shrinkOrder = (orderId: string) =>
    setExpandedOrders((prev) => prev.filter((arg) => arg !== orderId));

  const toggleIsExpanded = (orderId: string) => {
    if (isExpanded(orderId)) {
      shrinkOrder(orderId);
    } else {
      expandOrder(orderId);
    }
  };

  const toggleIsDishChecked = (orderId: string, dishId: string) => {
    setOrders((prev) =>
      prev.map((order) =>
        order.orderId === orderId
          ? {
              ...order,
              dishes: order.dishes.map((dish) =>
                dish.orderedDishId === dishId
                  ? { ...dish, isChecked: !dish.isChecked }
                  : dish
              ),
            }
          : order
      )
    );
  };

  useEffect(() => {
    if (orders && firstTimeOrdersExist.current) {
      firstTimeOrdersExist.current = false;
      orders.forEach(({ orderId }) => expandOrder(orderId));
    }
  }, [orders]);

  return (
    <OrdersContext.Provider
      value={{
        orders,
        changeOrderStatus,
        toggleIsDishChecked,
        isExpanded,
        toggleIsExpanded,
        expandOrder,
        shrinkOrder,
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
};

export const provideOrders = (Component: FC<any>): FC<any> => {
  return () => (
    <OrderProvider>
      <Component />
    </OrderProvider>
  );
};
