import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  height: 600,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

interface CancelModalProps {
  setOpenCancelModal: (open: boolean) => void;
}

const CancelModal = ({ setOpenCancelModal }: CancelModalProps) => {
  return (
    <div>
      <Modal open={true} onClose={() => setOpenCancelModal(false)}>
        <Box sx={style}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            This modal still in progress...
          </Typography>
          <Typography id='modal-modal-description' sx={{ mt: 2 }}>
            This modal suppose to contain an input why the business employee
            decide to cancel the order
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default CancelModal;
