import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import pickarLogo from '../../assets/images/pickarLogo.png';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { translate } from '../../translation/translate';

const styles = {
  appBarBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginX: 2,
    minWidth: 150,
  },
  button: {
    marginX: '3%',
    color: 'white',
    minWidth: 'max-content',
  },
  chosenButton: {
    marginX: '3%',
    color: 'white',
    minWidth: 'max-content',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'white',
  },
};

interface AppBarProps {
  chosenButton: string;
  setChosenButton: (name: string) => void;
}

const AppBar = ({ chosenButton, setChosenButton }: AppBarProps) => {
  const [time, setTime] = useState<Date>(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const buttons = ['Orders', 'Reports', 'Activity status', 'Menu', 'Settings'];
  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      flex={1}
      bgcolor={(theme) => theme.palette.primary.light}
    >
      <Box sx={styles.appBarBox}>
        <img
          style={{ width: 130, height: 30 }}
          alt='pickar logo'
          src={pickarLogo}
        />
      </Box>
      <Box sx={styles.appBarBox}>
        {buttons.map((name) => (
          <Button
            key={name}
            sx={name === chosenButton ? styles.chosenButton : styles.button}
            onClick={() => setChosenButton(name)}
          >
            {translate(name)}
          </Button>
        ))}
      </Box>
      <Box sx={styles.appBarBox}>
        <Typography variant='h4'>{`${String(time.getHours()).padStart(
          2,
          '0'
        )}:${String(time.getMinutes()).padStart(2, '0')}:${String(
          time.getSeconds()
        ).padStart(2, '0')}`}</Typography>
      </Box>
    </Box>
  );
};

export default AppBar;
