import Grid from '@mui/material/Grid';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import { translate } from '../../../translation/translate';
import Order from './Order/Order';
import { OrderMode, OrderStatus, OrderType, SortField } from './types';
import { grey } from '@mui/material/colors';
import Sort from './Sort';
import { useState } from 'react';
import { useOrders } from '../../../hooks/use-orders';

const styles: Record<string, SxProps<Theme>> = {
  container: {
    height: '100%',
    bgcolor: (theme) => theme.palette.background.paper,
  },
  modeContainer: {
    overflow: 'auto',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    position: 'relative',
    height: '100%',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bgcolor: (theme) => theme.palette.background.default,
    height: '6%',
    width: '100%',
  },
  scrollContainer: {
    width: '100%',
    overflow: 'auto',
    position: 'absolute',
    bottom: 0,
    top: '6%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    bgcolor: (theme) => theme.palette.background.default,
    '&::-webkit-scrollbar': {
      width: 5,
    },
    '&::-webkit-scrollbar-thumb': {
      bgcolor: 'grey',
      borderRadius: 1,
    },
  },
  count: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: grey[100],
    bgcolor: (theme) => theme.palette.primary.main,
    width: 20,
    height: 20,
    borderRadius: 10,
    marginX: 1,
  },
  sortButton: {
    position: 'absolute',
    left: 10,
  },
};

const initialOrdersModes: OrderMode[] = [
  {
    name: 'Received Orders',
    status: OrderStatus.NEW,
    sortBy: SortField.ORDER_TIME,
  },
  {
    name: 'In Progress Orders',
    status: OrderStatus.IN_PROGRESS,
    sortBy: SortField.ORDER_TIME,
  },
  {
    name: 'Ready Orders',
    status: OrderStatus.READY,
    sortBy: SortField.ORDER_TIME,
  },
  {
    name: 'Delivered orders',
    status: OrderStatus.DELIVERED,
    sortBy: SortField.ORDER_TIME,
  },
];

const OrdersView = () => {
  const [ordersModes, setOrdersModes] =
    useState<OrderMode[]>(initialOrdersModes);

  const { orders } = useOrders();

  const sortFunction = (
    field: SortField
  ): ((order1: OrderType, order2: OrderType) => number) => {
    return (order1, order2) => {
      if (field === SortField.DELIVER_TIME) {
        return order1.minutesToPick - order2.minutesToPick;
      }
      if (field === SortField.DISHES_COUNT) {
        return order1.dishes.length - order2.dishes.length;
      } else {
        return order1.orderTime - order2.orderTime;
      }
    };
  };

  return (
    <Grid container gap={1} sx={styles.container}>
      {ordersModes.map((orderMode) => (
        <Grid key={orderMode.name} item sx={styles.modeContainer}>
          <Box sx={styles.titleContainer}>
            <Typography fontWeight={'bold'}>
              {translate(orderMode.name)}
            </Typography>
            <Box sx={styles.count}>
              <Typography variant='body2'>
                {
                  orders.filter((order) => order.status === orderMode.status)
                    .length
                }
              </Typography>
            </Box>
            <Sort setOrdersModes={setOrdersModes} orderMode={orderMode} />
          </Box>
          <Box sx={styles.scrollContainer}>
            {orders
              .filter((order) => order.status === orderMode.status)
              .sort(sortFunction(orderMode.sortBy))
              .map((order) => (
                <Order key={order.orderId} order={order} />
              ))}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
export default OrdersView;
