import { IconButton, LinearProgress, SxProps, Theme } from '@mui/material';

import ClearIcon from '@mui/icons-material/Clear';

import { useEffect, useState } from 'react';

const styles: Record<string, SxProps<Theme>> = {
  approveButton: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: 60,
    height: 30,
    borderRadius: 1,
    borderWidth: 1,
    margin: 1,
    boxShadow: '2px 2px #e0dddd',
    overflow: 'hidden',
  },
  progressBar: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    bgcolor: (theme: Theme) => theme.palette.error.main,
  },
  icon: {
    zIndex: 1,
  },
};

interface ProgressButtonProps {
  seconds: number;
  onClick: () => void;
}

const ProgressButton = ({ seconds, onClick }: ProgressButtonProps) => {
  const delta = 100 / (seconds * 2);
  const [cancelTime, setCancelTime] = useState(100);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCancelTime((prev) => {
        if (prev > 0) {
          return prev - delta;
        } else {
          clearTimeout(intervalId);
          return 0;
        }
      });
    }, 500);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <IconButton sx={styles.approveButton} onClick={onClick}>
      <LinearProgress
        variant='determinate'
        color='secondary'
        sx={styles.progressBar}
        value={cancelTime}
      />
      <ClearIcon sx={styles.icon} />
    </IconButton>
  );
};

export default ProgressButton;
