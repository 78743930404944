import { OrderStatus, OrderType } from '../Components/Views/OrdersView/types';

export const ORDERS_MOCK: OrderType[] = [
  {
    orderId: '1#1681211945554',
    restaurantId: 1,
    orderName: '#203',
    minutesToPick: 29,
    orderTime: 1684873294000,
    status: OrderStatus.NEW,
    totalPrice: 478,
    currency: '₪',
    dishes: [
      {
        dishMenuId: 9,
        orderedDishId: '1681211897125#9',
        name: "קריספי צ'יקן בורגר",
        isChecked: false,
        selectedToppings: {
          לחמניה: {
            isValid: true,
            selectedItems: [
              {
                name: 'לחמניה רגילה',
                price: 0,
              },
            ],
          },
        },
        price: 49,
      },
      {
        dishMenuId: 2,
        orderedDishId: '1681211913996#2',
        name: 'ארוחה לרביעייה',
        isChecked: false,
        selectedToppings: {
          תוספות: {
            isValid: true,
            selectedItems: [
              {
                name: "צ'יפס בלגי",
                price: 0,
              },
              {
                name: 'סלט חסה',
                price: 0,
              },
              {
                name: 'טבעות בצל',
                price: 6,
              },
              {
                name: "צ'יפס בטטה",
                price: 0,
              },
            ],
          },
          לחמניה: {
            isValid: true,
            selectedItems: [
              {
                name: 'לחמניה רגילה',
                price: 0,
              },
            ],
          },
          שתיה: {
            isValid: true,
            selectedItems: [],
          },
        },
        price: 405,
      },
      {
        dishMenuId: 11,
        orderedDishId: '1681211919474#11',
        name: 'פיוז טי',
        isChecked: false,
        selectedToppings: null,
        price: 12,
      },
      {
        dishMenuId: 11,
        orderedDishId: '1681211922924#11',
        name: 'לימונדה',
        isChecked: false,
        selectedToppings: null,
        price: 12,
      },
    ],
    note: 'בלי מפיות',
  },
  {
    orderId: '1#1681211945553',
    restaurantId: 1,
    orderName: '#204',
    minutesToPick: 67,
    orderTime: 1684863274000,
    status: OrderStatus.NEW,
    totalPrice: 466,
    currency: '₪',
    dishes: [
      {
        dishMenuId: 9,
        orderedDishId: '1681211897125#9',
        name: "קריספי צ'יקן בורגר",
        isChecked: false,
        selectedToppings: {
          לחמניה: {
            isValid: true,
            selectedItems: [
              {
                name: 'לחמניה קמח מלא',
                price: 0,
              },
            ],
          },
        },
        price: 49,
      },
      {
        dishMenuId: 2,
        orderedDishId: '1681211913996#2',
        name: 'ארוחה זוגית',
        isChecked: false,
        selectedToppings: {
          תוספות: {
            isValid: true,
            selectedItems: [
              {
                name: "צ'יפס בלגי",
                price: 0,
              },
              {
                name: 'סלט חסה',
                price: 0,
              },
            ],
          },
          לחמניה: {
            isValid: true,
            selectedItems: [
              {
                name: 'לחמניה כוסמין',
                price: 0,
              },
            ],
          },
          שתיה: {
            isValid: true,
            selectedItems: [{ name: 'קוקה קולה זירו', price: 5 }],
          },
          'שתיה חמה': {
            isValid: true,
            selectedItems: [
              {
                name: 'קפה הפוך',
                price: 0,
              },
            ],
          },
          'שתיה קרה': {
            isValid: true,
            selectedItems: [
              {
                name: 'מיץ תפוזים סחוט',
                price: 0,
              },
            ],
          },
          'שתיה חריפה': {
            isValid: true,
            selectedItems: [
              {
                name: 'וודקה אבסולוט צייסר',
                price: 0,
              },
            ],
          },
          'שתיה מוגזת': {
            isValid: true,
            selectedItems: [
              {
                name: 'פנטה',
                price: 0,
              },
            ],
          },
          'שתיה טבעונית': {
            isValid: true,
            selectedItems: [
              {
                name: 'חלב שיבולת שועל',
                price: 0,
              },
            ],
          },
          'שתיה מיוחדת': {
            isValid: true,
            selectedItems: [
              {
                name: 'מי ורדים',
                price: 0,
              },
            ],
          },
        },
        price: 405,
      },
      {
        dishMenuId: 11,
        orderedDishId: '1681211919474#11',
        name: 'קולה זירו',
        isChecked: false,
        selectedToppings: {
          תוספות: {
            isValid: true,
            selectedItems: [
              {
                name: 'קרח',
                price: 0,
              },
              {
                name: 'ללא לימון',
                price: 0,
              },
            ],
          },
        },
        price: 12,
      },
    ],
    note: 'המוןןןןןןןן המון המון מיונזזז',
  },
  {
    orderId: '1#1681211945741',
    restaurantId: 1,
    orderName: '#188',
    minutesToPick: 12,
    orderTime: 1684862134000,
    status: OrderStatus.NEW,
    totalPrice: 478,
    currency: '₪',
    dishes: [
      {
        dishMenuId: 9,
        orderedDishId: '1681211897125#9',
        name: "קריספי צ'יקן בורגר",
        isChecked: false,
        selectedToppings: {
          לחמניה: {
            isValid: true,
            selectedItems: [
              {
                name: 'לחמניה רגילה',
                price: 0,
              },
            ],
          },
        },
        price: 49,
      },
      {
        dishMenuId: 2,
        orderedDishId: '1681211913996#2',
        name: 'ארוחה לרביעייה',
        isChecked: false,
        selectedToppings: {
          תוספות: {
            isValid: true,
            selectedItems: [
              {
                name: "צ'יפס בלגי",
                price: 0,
              },
              {
                name: 'סלט חסה',
                price: 0,
              },
              {
                name: 'טבעות בצל',
                price: 6,
              },
              {
                name: "צ'יפס בטטה",
                price: 0,
              },
            ],
          },
          לחמניה: {
            isValid: true,
            selectedItems: [
              {
                name: 'לחמניה רגילה',
                price: 0,
              },
            ],
          },
          שתיה: {
            isValid: true,
            selectedItems: [],
          },
        },
        price: 405,
      },
      {
        dishMenuId: 11,
        orderedDishId: '1681211919474#11',
        name: 'פיוז טי',
        isChecked: false,
        selectedToppings: null,
        price: 12,
      },
      {
        dishMenuId: 11,
        orderedDishId: '1681211922924#11',
        name: 'לימונדה',
        isChecked: false,
        selectedToppings: null,
        price: 12,
      },
    ],
    note: 'בלי מפיות',
  },
  {
    orderId: '1#1681211945963',
    restaurantId: 1,
    orderName: '#168',
    minutesToPick: 27,
    orderTime: 1684861294000,
    status: OrderStatus.NEW,
    totalPrice: 466,
    currency: '₪',
    dishes: [
      {
        dishMenuId: 9,
        orderedDishId: '1681211897125#9',
        name: "קריספי צ'יקן בורגר",
        isChecked: false,
        selectedToppings: {
          לחמניה: {
            isValid: true,
            selectedItems: [
              {
                name: 'לחמניה קמח מלא',
                price: 0,
              },
            ],
          },
        },
        price: 49,
      },
      {
        dishMenuId: 2,
        orderedDishId: '1681211913996#2',
        name: 'ארוחה זוגית',
        isChecked: false,
        selectedToppings: {
          תוספות: {
            isValid: true,
            selectedItems: [
              {
                name: "צ'יפס בלגי",
                price: 0,
              },
              {
                name: 'סלט חסה',
                price: 0,
              },
            ],
          },
          לחמניה: {
            isValid: true,
            selectedItems: [
              {
                name: 'לחמניה כוסמין',
                price: 0,
              },
            ],
          },
          שתיה: {
            isValid: true,
            selectedItems: [{ name: 'קוקה קולה זירו', price: 5 }],
          },
          'שתיה חמה': {
            isValid: true,
            selectedItems: [
              {
                name: 'קפה הפוך',
                price: 0,
              },
            ],
          },
          'שתיה קרה': {
            isValid: true,
            selectedItems: [
              {
                name: 'מיץ תפוזים סחוט',
                price: 0,
              },
            ],
          },
          'שתיה חריפה': {
            isValid: true,
            selectedItems: [
              {
                name: 'וודקה אבסולוט צייסר',
                price: 0,
              },
            ],
          },
          'שתיה מוגזת': {
            isValid: true,
            selectedItems: [
              {
                name: 'פנטה',
                price: 0,
              },
            ],
          },
          'שתיה טבעונית': {
            isValid: true,
            selectedItems: [
              {
                name: 'חלב שיבולת שועל',
                price: 0,
              },
            ],
          },
          'שתיה מיוחדת': {
            isValid: true,
            selectedItems: [
              {
                name: 'מי ורדים',
                price: 0,
              },
            ],
          },
        },
        price: 405,
      },
      {
        dishMenuId: 11,
        orderedDishId: '1681211919474#11',
        name: 'קולה זירו',
        isChecked: false,
        selectedToppings: {
          תוספות: {
            isValid: true,
            selectedItems: [
              {
                name: 'קרח',
                price: 0,
              },
              {
                name: 'ללא לימון',
                price: 0,
              },
            ],
          },
        },
        price: 12,
      },
    ],
    note: 'המוןןןןןןןן המון המון מיונזזז',
  },
];
