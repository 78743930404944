type Topping = {
  isValid: boolean;
  selectedItems: Array<{
    name: string;
    price: number;
  }>;
};

type SelectedToppings = Record<string, Topping>;

export interface DishItem {
  dishMenuId: number;
  orderedDishId: string;
  name: string;
  isChecked: boolean;
  selectedToppings: SelectedToppings | null;
  price: number;
}

export enum OrderStatus {
  NEW = 'new',
  IN_PROGRESS = 'in progress',
  READY = 'ready',
  DELIVERED = 'delivered',
}

export interface OrderType {
  orderId: string;
  orderName: string;
  status: OrderStatus;
  restaurantId: number;
  orderTime: number;
  minutesToPick: number;
  dishes: DishItem[];
  note: string;
  totalPrice:number;
  currency:string
}

export enum SortField {
  ORDER_TIME = 'Order time',
  DELIVER_TIME = 'Deliver time',
  DISHES_COUNT = 'Dishes count',
}

export interface OrderMode {
  name: string;
  status: OrderStatus;
  sortBy: SortField;
}

