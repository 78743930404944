import Box from '@mui/material/Box';
import Dish from '../Dish';
import {
  Collapse,
  IconButton,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { OrderType } from '../types';
import { translate } from '../../../../translation/translate';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import RamenDiningIcon from '@mui/icons-material/RamenDining';
import { useOrders } from '../../../../hooks/use-orders';
import ActionsLine from './ActionsLine';
import { useState } from 'react';
import CancelModal from './CancelModal';

const styles: Record<string, SxProps<Theme>> = {
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '95%',
    margin: 0.5,
    borderRadius: 3,
    border: (theme) => `2px solid ${theme.palette.primary.light} `,
    bgcolor: (theme) => theme.palette.background.paper,
    overflow: 'hidden',
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    bgcolor: (theme) => theme.palette.primary.light,
    cursor: 'pointer',
  },
  subHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  timeContainer: {
    display: 'flex',
    margin: 0.5,
    justifyContent: 'center',
    alignItems: 'center',
  },
};

interface OrderProps {
  order: OrderType;
}

const Order = ({ order }: OrderProps) => {
  const { isExpanded, toggleIsExpanded } = useOrders();
  const [openCancelModal, setOpenCancelModal] = useState(false);
  console.log(openCancelModal);

  return (
    <>
      <Box sx={styles.wrapper}>
        <Box sx={styles.container}>
          <Box
            sx={styles.header}
            onClick={() => toggleIsExpanded(order.orderId)}
          >
            <Typography fontWeight={'bold'} marginX={1}>
              {order.orderName}
            </Typography>
            <IconButton>
              {isExpanded(order.orderId) ? (
                <VisibilityOffIcon />
              ) : (
                <VisibilityIcon />
              )}
            </IconButton>
          </Box>
          <Box sx={styles.subHeader}>
            <Box sx={styles.timeContainer}>
              <AccessTimeIcon fontSize='small' />
              <Typography marginX={0.5}>
                {`${new Date(order.orderTime).getHours()}:${String(
                  new Date(order.orderTime).getMinutes()
                ).padStart(2, '0')}`}
              </Typography>
            </Box>
            <Box sx={styles.timeContainer}>
              <RamenDiningIcon fontSize='small' />
              <Typography marginX={0.5}>{order.dishes.length}</Typography>
            </Box>
            <Box sx={styles.timeContainer}>
              <EmojiPeopleIcon fontSize='small' />
              <Typography marginX={0.5}>{`${Math.floor(
                order.minutesToPick / 60
              )}:${String(order.minutesToPick % 60).padStart(
                2,
                '0'
              )}`}</Typography>
            </Box>
          </Box>
          <Collapse in={isExpanded(order.orderId)} sx={{ width: '100%' }}>
            <Box width={'100%'}>
              {order.dishes.map((dish, index) => (
                <Dish
                  dish={dish}
                  key={dish.orderedDishId}
                  index={index + 1}
                  order={order}
                />
              ))}

              <Box display={'flex'}>
                <Typography fontWeight={'bold'} paddingX={1.5}>
                  {`${translate('Consumer Note')}:`}
                </Typography>
                <Typography paddingRight={0.5}>{order.note}</Typography>
              </Box>
            </Box>
            <ActionsLine
              order={order}
              setOpenCancelModal={setOpenCancelModal}
            />
          </Collapse>
        </Box>
      </Box>
      {openCancelModal && (
        <CancelModal setOpenCancelModal={setOpenCancelModal} />
      )}
    </>
  );
};

export default Order;
