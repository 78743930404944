import { useState } from 'react';
import Box from '@mui/material/Box';
import OrdersView from './Views/OrdersView/OrdersView';

import AppBar from './AppBar/AppBar';
import { provideOrders } from '../context/OrdersContext';

const styles = {
  container: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },
  appBarBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    marginInline: '2%',
    color: 'white',
  },
  chosenButton: {
    borderWidth: 1,
    marginInline: '2%',
    borderColor: 'white',
    color: 'white',
    borderStyle: 'solid',
  },
};

const MainDrawer = () => {
  const [chosenButton, setChosenButton] = useState<string>('Orders');

  return (
    <Box
      sx={styles.container}
      bgcolor={(theme) => theme.palette.background.default}
    >
      <AppBar chosenButton={chosenButton} setChosenButton={setChosenButton} />
      <Box flex={12} width={'100%'} overflow={'hidden'}>
        {chosenButton === 'Orders' && <OrdersView />}
      </Box>
    </Box>
  );
};

export default provideOrders(MainDrawer);
