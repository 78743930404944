import MainDrawer from './Components/MainView';
import './App.css';
import { language } from './translation/translate';

function App() {
  return (
    <div dir={language === 'hebrew' ? 'rtl' : 'ltr'} style={{ height: '100%' }}>
      <MainDrawer />
    </div>
  );
}

export default App;
