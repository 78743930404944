import Box from '@mui/material/Box';
import { IconButton, SxProps, Theme, Typography } from '@mui/material';
import { OrderStatus, OrderType } from '../types';
import { language } from '../../../../translation/translate';
import CheckIcon from '@mui/icons-material/Check';
import PrintIcon from '@mui/icons-material/Print';
import { useOrders } from '../../../../hooks/use-orders';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import ProgressButton from './ProgressButton';

const styles: Record<string, SxProps<Theme>> = {
  approveButton: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: 60,
    height: 30,
    borderRadius: 1,
    borderWidth: 1,
    margin: 1,
    boxShadow: '2px 2px #e0dddd',
    overflow: 'hidden',
  },
  iconsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
};

interface ActionsLineProps {
  order: OrderType;
  setOpenCancelModal: (open: boolean) => void;
}

const ActionsLine = ({ order, setOpenCancelModal }: ActionsLineProps) => {
  const { changeOrderStatus } = useOrders();

  const onApproveClicked = () => {
    switch (order.status) {
      case OrderStatus.NEW:
        changeOrderStatus(order.orderId, OrderStatus.IN_PROGRESS);
        break;
      case OrderStatus.IN_PROGRESS:
        changeOrderStatus(order.orderId, OrderStatus.READY);
        break;
      case OrderStatus.READY:
        changeOrderStatus(order.orderId, OrderStatus.DELIVERED);
        break;
      default:
        break;
    }
  };

  const isApproveButtonDisable = (): boolean =>
    order.status === OrderStatus.IN_PROGRESS &&
    order.dishes.some((dish) => !dish.isChecked);

  const checkCount = order.dishes.reduce(
    (prev, dish) => (dish.isChecked ? prev + 1 : prev + 0),
    0
  );

  const getOrderColor = (theme: Theme): string | undefined => {
    switch (order.status) {
      case OrderStatus.NEW:
        return theme.palette.success.light;
      case OrderStatus.IN_PROGRESS:
        return theme.palette.info.light;
      case OrderStatus.READY:
        return theme.palette.warning.light;
      case OrderStatus.DELIVERED:
        return theme.palette.info.light;
      default:
        break;
    }
  };

  const ArrowIcon = () =>
    language === 'hebrew' ? <KeyboardBackspaceIcon /> : <TrendingFlatIcon />;

  return (
    <Box sx={{ ...styles.iconsContainer, justifyContent: 'space-between' }}>
      <Typography
        sx={styles.approveButton}
        fontWeight={'bold'}
        fontSize={13}
      >{`${order.totalPrice} ${order.currency}`}</Typography>
      <Box sx={styles.iconsContainer}>
        {order.status !== OrderStatus.NEW && (
          <IconButton sx={styles.approveButton}>
            <PrintIcon fontSize='small' />
          </IconButton>
        )}
        {order.status === OrderStatus.NEW && (
          <ProgressButton
            seconds={180}
            onClick={() => setOpenCancelModal(true)}
          />
        )}

        {order.status !== OrderStatus.DELIVERED && (
          <IconButton
            onClick={onApproveClicked}
            disabled={isApproveButtonDisable()}
            sx={{
              ...styles.approveButton,
              bgcolor: getOrderColor,
            }}
          >
            {order.status === OrderStatus.IN_PROGRESS &&
              isApproveButtonDisable() && (
                <Typography
                  variant={'caption'}
                >{`${checkCount}/${order.dishes.length}`}</Typography>
              )}

            {!isApproveButtonDisable() && (
              <>
                {order.status === OrderStatus.NEW ? (
                  <CheckIcon />
                ) : (
                  <ArrowIcon />
                )}
              </>
            )}
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default ActionsLine;
