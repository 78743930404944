import { createTheme } from '@mui/material/styles';

export const pickarTheme = createTheme({
  palette: {
    primary: { main: '#d13b00' },
    secondary: { main: '#929090' },
    background: {
      default: '#ffe1cb',
      paper: '#b6b6b625',
    },
  },
});
